import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { getPostEditorInitialPost } from '../../../common/store/post-editor/post-editor-selectors';
import { getPostFormInitialValues } from '../../../common/services/post-utils-rce';
import AppLoaded from '../../../common/components/app-loaded';
import PostEditorPageLayout from '../../components/post-editor-page-layout';
import PostForm from '../../components/post-form';

export class PostEditorPage extends Component {
  render() {
    const { initialPost } = this.props;

    if (!initialPost) {
      return null;
    }

    return (
      <PostEditorPageLayout data-hook="post-editor-page">
        <PostForm formInitialValues={getPostFormInitialValues(initialPost)} />
        <AppLoaded />
      </PostEditorPageLayout>
    );
  }
}

PostEditorPage.propTypes = {
  publish: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  initialPost: PropTypes.object,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  initialPost: getPostEditorInitialPost(state),
  publish: actions.triggerPostPublish,
  goBack: actions.goBack,
  handleCancel: actions.goBack,
});

export default connect(mapRuntimeToProps)(PostEditorPage);
