import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import Textarea from 'react-textarea-autosize';
import classNames from 'classnames';

import { CameraIcon } from '../../../common/components/icons/camera-icon';
import CommentFormImage from '../comment-form-image';
import withTranslate from '../../../common/hoc/with-translate';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withFastForm from '../../../common/components/fast-form/with-fast-form';
import { getImageUrl } from '../../../common/services/image-utils';
import { isDataUrl } from '../../../common/services/is-data-url';
import convertSimpleCommentToContentState from '../../../common/services/convert-simple-comment-to-content-state';
import styles from './comment-bar.scss';

export class CommentBar extends Component {
  handleContentChange = event => {
    const { onChange, fastForm } = this.props;
    onChange && onChange();
    fastForm.changeValue('content')(event.target.value);
  };

  handleFileChange = event => this.props.onImageChange(event.target.files[0]);

  renderImage() {
    const {
      fastForm: { values },
      onImageDelete: handleImageDelete,
    } = this.props;

    if (!values.image) {
      return null;
    }

    let src = values.image;
    const isLoading = isDataUrl(src);

    if (!isLoading) {
      src = getImageUrl(values.image);
    }

    return <CommentFormImage src={src} isLoading={isLoading} onDelete={handleImageDelete} />;
  }

  renderUploadButton() {
    const {
      fastForm: { values },
    } = this.props;

    if (values.image) {
      return null;
    }

    return (
      <div className={classNames(styles.uploadButton, 'comment-form__camera-icon')}>
        <CameraIcon className="blog-icon-fill" />
        <input className={styles.fileInput} type="file" onChange={this.handleFileChange} accept="image/*" />
      </div>
    );
  }

  setContentInput = ref => (this.contentInput = ref);

  render = () => {
    const {
      t,
      fastForm: { isSubmitting, isValid, values, submit },
      contentFontClassName,
    } = this.props;
    const isButtonVisible = get(values, 'content.length') || values.image;
    const buttonClassName = classNames(
      styles.saveButton,
      'blog-button-secondary-text-color',
      'comment-bar__send-button',
      { [styles.hidden]: !isButtonVisible },
    );

    return (
      <div className={contentFontClassName}>
        <div className={classNames(styles.container, 'blog-text-color', 'comment-bar')}>
          <div className={styles.content}>
            <Textarea
              inputRef={this.setContentInput}
              className={classNames(styles.textArea, 'comment-bar__content')}
              placeholder={t('text-editor.comment-placeholder')}
              value={values.content || ''}
              onChange={this.handleContentChange}
            />
            {this.renderImage()}
          </div>
          <div className={styles.buttonContainer}>
            {this.renderUploadButton()}
            <button className={buttonClassName} disabled={isSubmitting || !isValid} onClick={submit}>
              {t('comment-bar.send')}
            </button>
          </div>
        </div>
      </div>
    );
  };
}

CommentBar.propTypes = {
  t: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onImageChange: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  fastForm: PropTypes.object.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
};

CommentBar.FormConfig = {
  formName: 'comment-mobile',
  fields: ['content', 'ownerId', 'image'],
  validate: ({ image, content }) => {
    if (!image && !content) {
      return { content: 'empty' };
    }

    if (isDataUrl(image)) {
      return { image: 'loading' };
    }
  },
};

const mapRuntimeToProps = (state, ownProps) => ({
  onSubmit: ({ values }, fastForm) =>
    ownProps.onSubmit({ values: convertSimpleCommentToContentState(values) }, fastForm),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withFastForm(CommentBar.FormConfig),
  withTranslate,
  withFontClassName,
)(CommentBar);
