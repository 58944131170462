import { flowRight, isEqual } from 'lodash';
import React from 'react';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';
import connect from '../../common/components/runtime-context/connect';

import { MODAL_TYPE_POST_EDITOR } from '@wix/communities-blog-client-common/dist/src/constants/modal-types';
import { convertToRaw } from '../../common/services/post-utils-rce';
import { isContentStateEmpty } from '../../common/services/content-state-utils';
import { isInPostEditor } from '../../common/services/detect-route';
import { getIsModalDisplayed } from '../../common/modals/framework/store/modal-selectors';
import { getRoute } from '../../common/router/router-selectors';
import hasActiveUploads from '@wix/communities-blog-client-common/dist/src/rich-content/services/has-active-uploads';
import { getPostEditorPostId, getPostEditorDraftKey } from '../../common/store/post-editor/post-editor-selectors';
import POST_FORM_CONFIG from '../services/post-form-config';
import withFastForm from '../../common/components/fast-form/with-fast-form';

const isEmptyPost = (title, contentSate) => !(title && contentSate && !isContentStateEmpty(contentSate));
const getDraft = (postId, formValues = {}) => {
  const { content, ...values } = formValues;
  return { ...values, content: content && convertToRaw(content), _id: postId };
};

let lastSavedDraft = null;

const withPostPublish = (config = {}) => WrappedComponent => {
  class WithPostPublish extends React.Component {
    static displayName = `withPostPublish(${getDisplayName(WrappedComponent)})`;

    constructor(props) {
      super(props);
      if (!config.skipInitialize) {
        lastSavedDraft = getDraft(props.postId, props.fastForm.values);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  hoistNonReactStatics(WithPostPublish, WrappedComponent);

  const mapRuntimeToProps = (state, ownProps, actions) => {
    const fastForm = ownProps.fastForm;
    const postId = getPostEditorPostId(state);
    const draftKey = getPostEditorDraftKey(state);

    const savePostDraft = ({ skipEditPage } = {}) =>
      new Promise(resolve => {
        const contentSate = fastForm.values.content;
        const title = fastForm.values.title;
        if (
          isEmptyPost(title, contentSate) ||
          hasActiveUploads(contentSate) ||
          isEqual(getDraft(postId, fastForm.values), lastSavedDraft)
        ) {
          return resolve(lastSavedDraft);
        }

        fastForm.submit(({ values }) => {
          const content = convertToRaw(values.content);
          actions
            .savePostDraftPromisified({
              ...values,
              content,
              _id: postId,
              draftKey,
            })
            .then(post => {
              if (!isInPostEditor(getRoute(state))) {
                fastForm.stopSubmit();
                return resolve({ ...post.draft, _id: post._id });
              }

              lastSavedDraft = { ...values, content, _id: post._id };
              actions.updatePostEditorPost(post);
              fastForm.stopSubmit();

              if (!postId && !skipEditPage) {
                actions.goToEditPage(post._id);
              }
              resolve({ ...post.draft, _id: post._id });
            });
        });
      });

    return {
      savePostDraft,
      publishPost: () =>
        savePostDraft({ skipEditPage: true })
          .then(({ _id }) => actions.publishPostPromisified(_id))
          .then(({ slug }) => {
            getIsModalDisplayed(state, MODAL_TYPE_POST_EDITOR) && actions.closeModal({ type: MODAL_TYPE_POST_EDITOR });
            actions.navigateWithinPostPage(`/${slug}`);
          }),
    };
  };

  return flowRight(
    withFastForm(POST_FORM_CONFIG, config),
    connect(mapRuntimeToProps),
  )(WithPostPublish);
};

export default withPostPublish;
