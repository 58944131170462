import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { ModalCentered } from '../../../common/components/modals/base/modal-centered';
import DeleteConfirmation from '../../../common/components/delete-confirmation';
import withTranslate from '../../../common/hoc/with-translate';

export const DeleteCommentModal = ({ closeModal, deleteComment, t }) => (
  <ModalCentered onClose={closeModal}>
    <DeleteConfirmation
      title={t('delete-comment-modal.delete-comment')}
      message={t('delete-comment-modal.are-you-sure')}
      onConfirm={deleteComment}
      onCancel={closeModal}
    />
  </ModalCentered>
);

DeleteCommentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  postId: PropTypes.string.isRequired,
  commentId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, { postId, commentId, closeModal }) => ({
  deleteComment: () => closeModal({ postId, commentId }),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(DeleteCommentModal);
