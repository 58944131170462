import { get, find } from 'lodash';
import { createSelector } from 'reselect';
import { getCurrentUser } from '@wix/communities-blog-client-common/dist/src/selectors/current-user-selectors';
import { getWriters } from '../../selectors/writers-selectors';
import { isPublished } from '@wix/communities-blog-universal/dist/src/post-utils';
import { hasUnpublishedChanges } from '../../services/post-utils';

export const getPostEditor = state => state.postEditor;

export const getPostEditorPost = createSelector(
  getPostEditor,
  postEditor => postEditor.post,
);

export const getPostEditorDraftKey = createSelector(
  getPostEditor,
  postEditor => postEditor.draftKey,
);

export const getPostEditorInitialDraftKey = createSelector(
  getPostEditor,
  postEditor => postEditor.initialDraftKey,
);

export const getPostEditorPostId = createSelector(
  getPostEditor,
  postEditor => get(postEditor, 'post._id'),
);

export const getPostEditorInitialPost = createSelector(
  getPostEditor,
  postEditor => postEditor.initialPost,
);

export const getIsEditingDraft = createSelector(
  getPostEditorInitialPost,
  initialPost => Boolean(!isPublished(initialPost) && get(initialPost, '_id')),
);

export const getIsEditingPost = createSelector(
  getPostEditorInitialPost,
  initialPost => isPublished(initialPost),
);

export const getPostEditorPostOwner = createSelector(
  getPostEditorPost,
  getCurrentUser,
  getWriters,
  (post, currentUser, writers) => {
    if (!post.owner) {
      return currentUser || {};
    }

    const draftOwnerId = get(post, 'draft.ownerId');
    if (post.ownerId !== draftOwnerId) {
      return find(writers, writer => writer._id === draftOwnerId);
    }

    return post.owner;
  },
);

export const getHasUnpublishedChanges = createSelector(
  state => state,
  getPostEditorPost,
  (state, post) => hasUnpublishedChanges(post, state),
);
